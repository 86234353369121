import { createCookieBanner } from '@wg/gdpr-cookie-banner';

interface windowWithOneTrustId extends Window {
  OneTrustId?: string;
}

if (!/WOWS\/1\.0/i.test(window.navigator && window.navigator.userAgent)) {
  createCookieBanner({
    oneTrustId: (window as windowWithOneTrustId).OneTrustId,
  });
}
